import {parse} from '@tinyhttp/content-disposition';
import {
  BASE_URL,
  createHeadersAsync,
  createHeadersWithNoContentAsync,
  getErrorCode,
} from '../constants';

type RevokeCertificateResult = {
  error?: string;
};

type IssueCertificateResult = RevokeCertificateResult;

export enum CertificateType {
  iOS = 0,
  Other = 1,
}

type IssueCertificateRequest = {
  type: CertificateType;
};

export async function issueAndDownloadCertificateAsync(
  medical_institution_id: string,
  body: IssueCertificateRequest
): Promise<IssueCertificateResult> {
  const response = await fetch(
    `${BASE_URL}/certificates/${medical_institution_id}`,
    {
      headers: await createHeadersAsync(),
      method: 'POST',
      body: JSON.stringify(body),
    }
  );

  const contentDispositionHeader = response.headers.get('content-disposition');

  if (contentDispositionHeader === null || !response.ok) {
    return {
      error: await getErrorCode(response),
    };
  }

  const contentDisposition = parse(contentDispositionHeader);
  const filename = contentDisposition.parameters['filename'] as string;

  // テスト環境では、上記の代わりに以下をコメントアウトして使用する。
  // API GatewayによってデータがBase64エンコードされるため
  // const blob = await response.blob();
  const blob = await response.blob();
  const text = await blob.text();
  const data = await (
    await fetch(`data:application/octet-stream;base64,${text}`)
  ).blob(); // Base64デコードする

  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(data);
  a.download = filename;
  a.click();

  return {};
}

export type Certificate = {
  serialNumber: string;
  revoked: boolean;
};

type ListCertificatesResult =
  | {
      certificates: Certificate[];
      error?: undefined;
    }
  | {
      certificates?: undefined;
      error: string;
    };

export async function listCertificatesAsync(
  medical_institution_id: string
): Promise<ListCertificatesResult> {
  type ApiCertificate = {
    serial_number: string;
    revoked: boolean;
  };

  return fetch(`${BASE_URL}/certificates/${medical_institution_id}`, {
    headers: await createHeadersWithNoContentAsync(),
    method: 'GET',
  })
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }
      return response.json() as Promise<ApiCertificate[]>;
    })
    .then(json => {
      return {
        certificates: json.map(v => ({
          serialNumber: v.serial_number,
          revoked: v.revoked,
        })) as Certificate[],
      };
    })
    .catch(error => {
      return {error: error};
    });
}

export async function revokeCertificatesAsync(
  medical_institution_id: string,
  serialNumber: string
): Promise<RevokeCertificateResult> {
  return fetch(
    `${BASE_URL}/certificates/${medical_institution_id}/${serialNumber}`,
    {
      headers: await createHeadersWithNoContentAsync(),
      method: 'PUT',
    }
  )
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }
      return {};
    })
    .catch(error => {
      return {error: error};
    });
}
