import {z} from 'zod';
import {
  BASE_URL,
  createHeadersAsync,
  createHeadersWithNoContentAsync,
  getErrorCode,
} from '../constants';
import i18next from '../i18n';

export type Approver = {
  id: number;
  name: string;
  mailAddress: string;
  medicalSpecialty: number | undefined;
};

export type ApproverCreate = {
  name: string;
  mailAddress: string;
  medicalSpecialty: number | undefined;
};

type ListApproversResult =
  | {
      approvers: Approver[];
      error?: undefined;
    }
  | {
      approvers?: undefined;
      error: string;
    };

export const ApproverCreateRequest = z
  .object({
    name: z
      .string({required_error: i18next.t('承認者名は必須です。')})
      .min(1, {message: i18next.t('承認者名は1文字以上である必要があります。')})
      .max(50, {
        message: i18next.t('承認者名は50文字以下である必要があります。'),
      }),
    mail: z
      .string({required_error: i18next.t('メールアドレスは必須です。')})
      .email({message: i18next.t('メールアドレスは無効です。')})
      .max(254, {
        message: i18next.t('メールアドレスは254文字以下である必要があります。'),
      }),
    mailConfirm: z
      .string({required_error: i18next.t('メールアドレス(確認)は必須です。')})
      .email({message: i18next.t('メールアドレス(確認)は無効です。')})
      .max(254, {
        message: i18next.t(
          'メールアドレス(確認)は254文字以下である必要があります。'
        ),
      }),
    medicalSpecialty: z
      .number({required_error: i18next.t('診療科は必須です。')})
      .nullable()
      .transform(x => x ?? undefined),
  })
  .superRefine(({mail, mailConfirm}, ctx) => {
    if (mail !== mailConfirm) {
      ctx.addIssue({
        path: ['mailConfirm'],
        code: 'custom',
        message: i18next.t(
          '承認者メールアドレスと承認者メールアドレス(確認)が一致しません。'
        ),
      });
    }
  });

export const UpdateApproverRequest = ApproverCreateRequest;

export type CreateApproverRequestType = z.infer<typeof ApproverCreateRequest>;
export type UpdateApproverRequestType = z.infer<typeof UpdateApproverRequest>;

type UpdateApproverResult =
  | {
      approver: Approver;
      error?: undefined;
    }
  | {
      approver?: undefined;
      error: string;
    };

type ApiApproverResult = {
  id: number;
  medical_specialty_id: number;
  name: string;
  mail_address: string;
};

export async function listApproversAsync(
  medicalInstitutionId: string
): Promise<ListApproversResult> {
  return fetch(
    `${BASE_URL}/medical_institutions/${medicalInstitutionId}/approvers`,
    {
      headers: await createHeadersWithNoContentAsync(),
      method: 'GET',
    }
  )
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }
      return response.json() as Promise<ApiApproverResult[]>;
    })
    .then(json => {
      const approvers = json.map(v => ({
        id: v.id,
        name: v.name,
        mailAddress: v.mail_address,
        medicalSpecialty: v.medical_specialty_id,
      })) as Approver[];
      return {approvers: approvers};
    })
    .catch(error => {
      return {error: error};
    });
}

type DeleteApproverType = {
  error?: string;
};

export async function deleteApproverAsync(
  medicalInstitutionId: string,
  approverId: string
): Promise<DeleteApproverType> {
  return fetch(
    `${BASE_URL}/medical_institutions/${medicalInstitutionId}/approvers/${approverId}`,
    {
      headers: await createHeadersWithNoContentAsync(),
      method: 'DELETE',
    }
  )
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }
      return response.json();
    })
    .catch(error => {
      return {error: error};
    });
}

export async function updateApproverAsync(
  medicalInstitutionId: string,
  approverId: string,
  body: ApproverCreate
): Promise<UpdateApproverResult> {
  return fetch(
    `${BASE_URL}/medical_institutions/${medicalInstitutionId}/approvers/${approverId}`,
    {
      headers: await createHeadersAsync(),
      method: 'PUT',
      body: JSON.stringify({
        name: body.name,
        mail_address: body.mailAddress,
        medical_specialty_id: body.medicalSpecialty,
      }),
    }
  )
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }
      return response.json();
    })
    .catch(error => {
      return {error: error};
    });
}

type GetApproverResult =
  | {
      approver: Approver;
      error?: undefined;
    }
  | {
      approver?: undefined;
      error: string;
    };

export async function getApproverAsync(
  medicalInstitutionId: string,
  approverId: string
): Promise<GetApproverResult> {
  return fetch(
    `${BASE_URL}/medical_institutions/${medicalInstitutionId}/approvers/${approverId}`,
    {
      headers: await createHeadersWithNoContentAsync(),
      method: 'GET',
    }
  )
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }
      return response.json() as Promise<ApiApproverResult>;
    })
    .then(json => {
      return {
        approver: {
          id: json.id,
          mailAddress: json.mail_address,
          name: json.name,
          medicalSpecialty: json.medical_specialty_id,
        } as Approver,
      };
    })
    .catch(error => {
      return {error: error};
    });
}

type CreateApproverResult = DeleteApproverType;

export async function createApproverAsync(
  medicalInstitutionId: string,
  body: ApproverCreate
): Promise<CreateApproverResult> {
  return fetch(
    `${BASE_URL}/medical_institutions/${medicalInstitutionId}/approvers`,
    {
      headers: await createHeadersAsync(),
      method: 'POST',
      body: JSON.stringify({
        name: body.name,
        mail_address: body.mailAddress,
        medical_specialty_id: body.medicalSpecialty,
      }),
    }
  )
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }
      return response.json();
    })
    .catch(error => {
      return {error: error};
    });
}
