import {CreateApproverForm} from './ui-components';
import {useEffect, useState} from 'react';
import {Button, Flex, Heading, Loader, Message} from '@aws-amplify/ui-react';
import {useLocation, useParams, useNavigate} from 'react-router-dom';
import React from 'react';
import Modal from 'react-modal';
import {
  MedicalSpecialty,
  getMedicalSpecialtiesAsync,
} from './api/medicalSpecialties';
import {
  UpdateApproverRequest,
  UpdateApproverRequestType,
  deleteApproverAsync,
  getApproverAsync,
  updateApproverAsync,
} from './api/approvers';
import {useTranslation} from 'react-i18next';
import {getErrorMessage} from './constants';

function EditApprover() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {state} = useLocation();
  const {id, approverId} = useParams();

  const [errors, setErrors] = useState<string[]>([]);
  const [medicalSpecialties, setMedicalSpecialties] = useState<
    MedicalSpecialty[]
  >([]);
  const [formData, setFormData] = useState<UpdateApproverRequestType>({
    name: '',
    mail: '',
    mailConfirm: '',
    medicalSpecialty: undefined,
  });
  const [modalOpened, setModalOpened] = useState(false);
  // ロード中
  const [loading, setLoading] = useState(false);
  // API実行中
  const [submitting, setSubmitting] = useState(false);

  if (!id || !approverId) {
    throw new Error('id and approverId are required.');
  }

  /** バリデーションを実行する。 */
  const validate = (data: UpdateApproverRequestType) => {
    const result = UpdateApproverRequest.safeParse(data);

    if (result.success) {
      setErrors([]);
    } else {
      const message = result.error.errors.map(x => x.message);
      setErrors(message);
    }
  };

  /** フォームの内容変更時に呼び出すハンドラー
   *
   * 状態を変更し、バリデーションを実行する。*/
  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const dataToValidate = formData;

    switch (e.target.id) {
      case 'name':
        setFormData({...formData, name: e.target.value});
        dataToValidate.name = e.target.value;
        break;
      case 'mail':
        setFormData({...formData, mail: e.target.value});
        dataToValidate.mail = e.target.value;
        break;
      case 'mail-confirm':
        setFormData({...formData, mailConfirm: e.target.value});
        dataToValidate.mailConfirm = e.target.value;
        break;
      case 'medical-specialty': {
        const selected = medicalSpecialties.find(
          v => v.name === e.target.value
        );
        setFormData({...formData, medicalSpecialty: selected?.id});
        dataToValidate.medicalSpecialty = selected?.id;
        break;
      }
    }

    validate(dataToValidate);
  };

  // 画面遷移時の処理
  useEffect(() => {
    setLoading(true);

    // stateが取得できるときは承認者名、メールアドレスを取得して設定する。そうでなければAPIを使って取得してくる。
    // URLで直接アクセスしたときなどはstateを取得できない
    if (!state) {
      getApproverAsync(id, approverId).then(response => {
        if (!response.approver) {
          alert(t(getErrorMessage(response.error)));
          navigate('./../../../'); // エラー時、医療機関画面に戻る
        } else {
          const data = {
            name: response.approver.name,
            mail: response.approver.mailAddress,
            mailConfirm: response.approver.mailAddress,
            medicalSpecialty: response.approver.medicalSpecialty,
          };

          // 診療科の取得
          getMedicalSpecialtiesAsync(id).then(response => {
            if (!response.medicalSpecialties) {
              alert(t(getErrorMessage(response.error)));
            } else {
              setMedicalSpecialties(response.medicalSpecialties);
              setLoading(false);
            }
          });

          setFormData(data);
          validate(data);
        }
      });
    } else {
      const {approver} = state;
      const data = {
        name: approver.name,
        mail: approver.mailAddress,
        mailConfirm: approver.mailAddress,
        medicalSpecialty: approver.medicalSpecialty,
      };

      // 診療科の取得
      getMedicalSpecialtiesAsync(id).then(response => {
        if (!response.medicalSpecialties) {
          alert(t(getErrorMessage(response.error)));
        } else {
          setMedicalSpecialties(response.medicalSpecialties);
          setLoading(false);
        }
      });

      setFormData(data);
      validate(data);
    }
  }, []);

  useEffect(() => {
    const medical_specialty_name = medicalSpecialties.find(
      v => v.id === formData.medicalSpecialty
    )?.name;
    const select = document.getElementById(
      'medical-specialty'
    ) as HTMLSelectElement;
    if (select !== null && medical_specialty_name !== undefined) {
      select.value = medical_specialty_name;
    }
  }, [medicalSpecialties]);

  const deleteAndNavigate = async () => {
    setSubmitting(true);
    const response = await deleteApproverAsync(id, approverId);
    if (!response.error) {
      navigate('./../../../'); // 成功時、医療機関画面に戻る
    } else {
      alert(t(getErrorMessage(response.error)));
      setSubmitting(false);
    }
  };

  const updateAndNavigate = async () => {
    setSubmitting(true);
    const result = await updateApproverAsync(id, approverId, {
      name: formData.name,
      mailAddress: formData.mail,
      medicalSpecialty: formData.medicalSpecialty,
    });
    if (!result.error) {
      navigate('./../../../'); // 成功時、医療機関画面に戻る
    } else {
      alert(t(getErrorMessage(result.error)));
      setSubmitting(false);
    }
  };

  return (
    <Flex direction="column" alignItems="center">
      {loading ? <Loader size="large" /> : <></>}
      <Modal
        isOpen={modalOpened}
        onRequestClose={() => setModalOpened(false)}
        style={{
          overlay: {},
          content: {
            top: '40%',
            left: '40%',
            right: 'auto',
            bottom: 'auto',
          },
        }}
      >
        <Flex direction="column" gap="3em">
          <Heading>
            {t('「name」を本当に削除しますか？', {name: formData.name})}
          </Heading>
          <Flex direction="row" gap="3em">
            <Button variation="primary" onClick={() => setModalOpened(false)}>
              {t('キャンセル')}
            </Button>
            <Button variation="destructive" onClick={deleteAndNavigate}>
              {t('削除する')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <Button
        variation="primary"
        style={{marginLeft: 'auto'}}
        colorTheme="error"
        disabled={loading || submitting}
        onClick={() => setModalOpened(true)}
      >
        {t('承認者を削除する')}
      </Button>
      <CreateApproverForm
        overrides={{
          Header: {
            children: t('承認者情報'),
          },
          Name: {
            id: 'name',
            value: formData.name,
            label: t('承認者名'),
            placeholder: t('承認 太郎'),
            disabled: loading,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e),
          },
          MailAddress: {
            id: 'mail',
            value: formData.mail,
            disabled: loading,
            label: t('承認者メールアドレス'),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e),
          },
          MailAddressConfirm: {
            id: 'mail-confirm',
            value: formData.mailConfirm,
            disabled: loading,
            label: t('承認者メールアドレス(確認)'),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e),
          },
          MedicalSpecialty: {
            id: 'medical-specialty',
            options: medicalSpecialties.map(v => v.name),
            isRequired: true,
            disabled: loading,
            label: t('診療科'),
            onChange: (
              e: React.ChangeEvent<HTMLSelectElement> &
                React.ChangeEvent<HTMLDivElement>
            ) => onChange(e),
          },
          Button: {
            onClick: updateAndNavigate,
            children: t('承認者情報の登録'),
            isDisabled: loading || errors.length > 0 || submitting,
          },
        }}
      />
      {errors.length > 0 ? (
        errors.map((error: string, index: number) => (
          <Message key={index} colorTheme="warning">
            {error}
          </Message>
        ))
      ) : (
        <></>
      )}
    </Flex>
  );
}

export default EditApprover;
