import {Auth} from 'aws-amplify';

interface GlobalConfig {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  baseUrl: string;
}

declare global {
  interface Window {
    globalConfig: GlobalConfig;
  }
}

export const appConfig = window.globalConfig || {
  // windows.globalConfigから取得できないときのデフォルト設定
  // 開発時などはこちらが参照される
  region: 'ap-northeast-1',
  userPoolId: 'ap-northeast-1_CnhMsOTD3',
  userPoolWebClientId: '58p5pvge6fa76122g7ukim20g2',
  baseUrl: 'http://localhost:8000',
};

export const BASE_URL = appConfig.baseUrl;

// https://docs.amplify.aws/lib/auth/manage-session/q/platform/js/#retrieve-a-user-session
async function currentSession() {
  const token = await Auth.currentSession();
  return token.getIdToken().getJwtToken();
}

export async function createHeadersAsync() {
  return {
    Authorization: `Bearer ${await currentSession()}`,
    'Content-Type': 'application/json',
  };
}

export async function createHeadersWithNoContentAsync() {
  return {Authorization: `Bearer ${await currentSession()}`};
}

/** Response からエラーコードを取り出す。できない場合、"Unknown Error"を返す。*/
export async function getErrorCode(response: Response): Promise<string> {
  type ErrorType = {
    error_code?: string;
    message?: string;
  };

  // Content-Typeがjson以外の場合、不明なエラーとする
  if (response.headers.get('Content-Type') !== 'application/json') {
    return 'Unknown Error';
  }

  const json = (await response.json()) as ErrorType;

  // jsonにerror_codeが含まれない場合、不明なエラーとする
  return json.error_code || 'Unknown Error';
}

export function getErrorMessage(error_code: string) {
  let error_message = '';
  switch (error_code) {
    case 'E006':
      error_message = '証明書が無効です。';
      break;
    case 'E007':
      error_message =
        '承認者が存在しているため、医療機関または診療科を削除できません。医療機関や診療科を削除する前に、所属する承認者を削除するか、または、承認者の所属診療科を変更する必要があります。';
      break;
    case 'E008':
      error_message =
        '有効な証明書が存在しているため、医療機関を削除できません。医療機関を削除する前に、証明書を失効させる必要があります。';
      break;
    case 'E009':
      error_message = '診療科名が重複しているため、診療科の登録ができません。';
      break;
    case 'E010':
      error_message =
        'メールアドレスが重複しているため、承認者の登録ができません。';
      break;
    default:
      error_message = 'エラー: コード ' + error_code;
  }

  return error_message;
}
