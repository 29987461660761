import {
  BASE_URL,
  createHeadersAsync,
  createHeadersWithNoContentAsync,
  getErrorCode,
} from '../constants';
import {z} from 'zod';
import i18next from '../i18n';

export type MedicalSpecialty = {
  id: number;
  name: string;
};

export type GetMedicalSpecialtiesResult =
  | {
      medicalSpecialties: MedicalSpecialty[];
      error?: undefined;
    }
  | {
      medicalSpecialties?: undefined;
      error: string;
    };

export type CreateMedicalSpecialtyRequest = {
  name: string;
};

export type UpdateMedicalSpecialtyRequest = CreateMedicalSpecialtyRequest;

export type CreateMedicalSpecialtiesResult = {
  error?: undefined;
};

export type UpdateMedicalSpecialtyResult = CreateMedicalSpecialtiesResult;
export type GetMedicalSpecialtyResult =
  | {
      medicalSpecialty: MedicalSpecialty;
      error?: undefined;
    }
  | {
      medicalSpecialty?: undefined;
      error: string;
    };

export const MedicalSpecialtyUpdateRequest = z.object({
  name: z
    .string({required_error: i18next.t('診療科名は必須です。')})
    .min(1, {message: i18next.t('診療科名は1文字以上である必要があります。')})
    .max(50, {
      message: i18next.t('診療科名は50文字以下である必要があります。'),
    }),
});

export const MedicalSpecialtyCreateRequest = MedicalSpecialtyUpdateRequest;

export type MedicalSpecialtyUpdateRequestType = z.infer<
  typeof MedicalSpecialtyUpdateRequest
>;
export type MedicalSpecialtyCreateRequestType = z.infer<
  typeof MedicalSpecialtyCreateRequest
>;

type ApiMedicalSpecialty = {
  medical_specialty_id: number;
  name: string;
};

export async function getMedicalSpecialtiesAsync(
  medicalInstitutionId: string
): Promise<GetMedicalSpecialtiesResult> {
  type ApiGetMedicalSpecialties = {
    medical_specialties: ApiMedicalSpecialty[];
  };

  return fetch(
    `${BASE_URL}/medical_institutions/${medicalInstitutionId}/medical_specialties`,
    {
      headers: await createHeadersWithNoContentAsync(),
      method: 'GET',
    }
  )
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }
      return await (response.json() as Promise<ApiGetMedicalSpecialties>);
    })
    .then(response => {
      return {
        medicalSpecialties: response.medical_specialties.map(v => ({
          id: v.medical_specialty_id,
          name: v.name,
        })),
      };
    })
    .catch(error => {
      return {error: error};
    });
}

export async function getMedicalSpecialtyAsync(
  medicalInstitutionId: string,
  id: number
): Promise<GetMedicalSpecialtyResult> {
  return fetch(
    `${BASE_URL}/medical_institutions/${medicalInstitutionId}/medical_specialties/${id}`,
    {
      headers: await createHeadersWithNoContentAsync(),
      method: 'GET',
    }
  )
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }
      return await (response.json() as Promise<ApiMedicalSpecialty>);
    })
    .then(response => {
      return {
        medicalSpecialty: {
          id: response.medical_specialty_id,
          name: response.name,
        },
      };
    })
    .catch(error => {
      return {error: error};
    });
}

export async function createMedicalSpecialtiesAsync(
  medicalInstitutionId: string,
  body: CreateMedicalSpecialtyRequest
): Promise<CreateMedicalSpecialtiesResult> {
  return fetch(
    `${BASE_URL}/medical_institutions/${medicalInstitutionId}/medical_specialties`,
    {
      headers: await createHeadersAsync(),
      method: 'POST',
      body: JSON.stringify(body),
    }
  )
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }

      return {};
    })
    .catch(error => {
      return {error: error};
    });
}

type deleteMedicalSpecialtyRequest = {
  error?: string;
};

export async function deleteMedicalSpecialtyAsync(
  medicalInstitutionId: string,
  id: number
): Promise<deleteMedicalSpecialtyRequest> {
  return await fetch(
    `${BASE_URL}/medical_institutions/${medicalInstitutionId}/medical_specialties/${id}`,
    {
      headers: await createHeadersWithNoContentAsync(),
      method: 'DELETE',
    }
  )
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }

      return {};
    })
    .catch(error => {
      return {error: error};
    });
}

export async function updateMedicalSpecialtiesAsync(
  medicalInstitutionId: string,
  id: number,
  body: UpdateMedicalSpecialtyRequest
): Promise<UpdateMedicalSpecialtyResult> {
  return fetch(
    `${BASE_URL}/medical_institutions/${medicalInstitutionId}/medical_specialties/${id}`,
    {
      headers: await createHeadersAsync(),
      method: 'PUT',
      body: JSON.stringify(body),
    }
  )
    .then(async response => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }
      return {};
    })
    .catch(error => {
      return {error: error};
    });
}
